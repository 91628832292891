import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';

export class PrivacyPolicy extends React.Component {

  render() {
    return (
      <div className="PrivacyPolicy">

        <div className="Container HeightPadding">

          <h1><Translate id="PrivacyPolicy" /></h1>

          <div className="AddressBlock">
            <p><Translate id="PP_Add_1" /></p>
            <p><Translate id="PP_Add_2" /></p>
            <p><Translate id="PP_Add_3" /></p>
            <p><Translate id="PP_Add_4" /></p>
            <p><Translate id="PP_Add_5" /></p>
            <p><Translate id="PP_Add_6" /></p>
            <p><Translate id="PP_Add_7" /></p>
          </div>

          <div className="LineSpacer3"></div>

          <div className="AddressBlock">
            <p><Translate id="PP_Add_8" /></p>
            <p><Translate id="PP_Add_9" /></p>
          </div>

          <div className="LineSpacer6"></div>

          <p><Translate id="PP_01" /></p>
          <p><Translate id="PP_01_1" /></p>
          <p><Translate id="PP_01_2" /></p>
          <p><Translate id="PP_01_3" /></p>
          <p><Translate id="PP_01_4" /></p>
          <p><Translate id="PP_01_5" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_02" /></p>
          <p><Translate id="PP_02_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_03" /></p>
          <p><Translate id="PP_03_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_04" /></p>
          <p><Translate id="PP_04_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_05" /></p>
          <p><Translate id="PP_05_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_06" /></p>
          <p><Translate id="PP_06_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_07" /></p>
          <p><Translate id="PP_07_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_08" /></p>
          <p><Translate id="PP_08_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_09" /></p>
          <p><Translate id="PP_09_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_10" /></p>
          <p><Translate id="PP_10_1" /></p>
          <p><Translate id="PP_10_2" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_11" /></p>
          <p><Translate id="PP_11_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_12" /></p>
          <p><Translate id="PP_12_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_13" /></p>
          <p><Translate id="PP_13_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_14" /></p>
          <p><Translate id="PP_14_1" /></p>

          <div className="LineSpacer3"></div>

          <p><Translate id="PP_15" /></p>
          <p><Translate id="PP_15_1" /></p>

          <div class="LineSpacer3"></div>

          <p><Translate id="PP_UpdateDate" /></p>

          <div class="LineSpacer6"></div>                                      

          <div className="BottomLinks">
            <div className="Btn BtnRight">
              <Link to="/register">
                <Translate id="Register" />
              </Link>
            </div>
            <div className="BottomFix" />
          </div>

        </div>
      </div>
    );
  }
}
export default withLocalize(PrivacyPolicy);