const Data = [
  {
    id: 93,
    title: "Emotional Strengths",
    slogan: "How you manage your emotions (6 STRENGTHS)",
    list: [
      {
        id: "00",
        title: "Courage",
        content:
          "You take on challenges and face risks by standing up for what you believe",
        iconCode: "Courage",
        description:
          "I enjoy challenging the status quo and established ways of doing things, even if this means that I am at odds with public opinion or putting myself in opposition to those with more authority than myself",
        bullets: [
          {
            bullet:
              "Are able to stand up for what you believe in, even when challenging authority or the status quo"
          },
          {
            bullet:
              "Are able to withstand personal risk, pressure and difficult circumstances"
          },
          {
            bullet:
              "Take tough stands based on your convictions, even if they are unpopular"
          }
        ]
      },
      {
        id: "01",
        title: "Emotional control",
        description:
          "I can recall situations perceived by others to be high pressure or stressful, but I was able to keep calm and ensured I appeared that way to others. I found it energizing to keep my nerves under control",
        content:
          "You are aware of your emotional ‘triggers’ and how to control these to ensure you remain calm and productive",
        iconCode: "EmControl",
        bullets: [
          {
            bullet:
              "Are aware of your feelings and emotional ‘trigger points’ in response to your environment "
          },
          {
            bullet:
              "Are able to quickly shift inappropriate or negative emotional states to more productive ones"
          },
          {
            bullet:
              "Control against emotional outbursts and remain calm under pressure"
          }
        ]
      },
      {
        id: "02",
        title: "Enthusiasm",
        content:
          "You demonstrate strong passion and energy when communicating goals, beliefs, interests or ideas you feel strongly about",
        iconCode: "Enthusiasm",
        description:
          "I believe there is no point in doing something if you are not going to give it your all or be passionate about it. I do things that I love to do, and find a way to enjoy the things I don’t",
        bullets: [
          {
            bullet:
              "Strongly advocate and champion views, ideas and beliefs that you support"
          },
          {
            bullet:
              "Talk with emotion and passion about people and things that really excite you"
          },
          {
            bullet:
              "When you experience something you really enjoy, you recommend it enthusiastically to others"
          }
        ]
      },
      {
        id: "03",
        title: "Optimism",
        content:
          "You remain positive and upbeat about the future and your ability to influence it to your advantage",
        iconCode: "Optimism",
        description:
          "Even when I am going through a tough period, I always feel that things will work out somehow, often without any concrete facts to back this up. I am typically upbeat and don’t stay down for long",
        bullets: [
          {
            bullet:
              "Believe that in the vast majority of cases, things will work out for the best"
          },
          {
            bullet:
              "Don’t let isolated negative events affect your positive view of the world"
          },
          {
            bullet: "Look first for the positive in people, plans and projects"
          }
        ]
      },
      {
        id: "04",
        title: "Resilience",
        content:
          "You deal effectively with setbacks and enjoy overcoming difficult challenges",
        iconCode: "Resilience",
        description:
          "I’m at my best when I have to find a way round a problem that has appeared. My sense of achievement comes from managing to resolve issues that everyone else has given up on",
        bullets: [
          { bullet: "Tend to see problems or threats as opportunities" },
          {
            bullet:
              "Are able to quickly recover from stressful or traumatic experiences"
          },
          {
            bullet:
              "Persist in solving challenging or complex tasks in the face of  stress, pressure or setbacks"
          }
        ]
      },
      {
        id: "05",
        title: "Self-confidence",
        content:
          "You have a strong belief in yourself and your abilities to accomplish tasks and goals",
        iconCode: "SC",
        description:
          "I have always believed that whatever I put my mind to, I can achieve. It motivates me to get the job done, because I believe that I can do it",
        bullets: [
          {
            bullet:
              "Have a high level of trust in your own abilities and judgement"
          },
          {
            bullet:
              "Have a belief that you will generally be able to do what you wish, plan, and expect"
          },
          {
            bullet:
              "Convey your ideas and opinions in a confident manner and have a positive impact on others"
          }
        ]
      }
    ]
  },
  {
    id: 13,
    title: "Relational Strengths",
    slogan: "How you build and maintain relationships (7 STRENGTHS)",
    list: [
      {
        id: "100",
        title: "Collaboration",
        content:
          "You work cooperatively with others to overcome conflict and build towards a common goal ",
        iconCode: "Collaboration",
        description:
          "I can work harder and longer when I am collaborating with colleagues or clients, and I really believe a lot more is achieved when we find common ground, rather than focusing on differences",
        bullets: [
          {
            bullet:
              "Maintain cooperative working relations across organisational boundaries"
          },
          {
            bullet: "Are able to build a common understanding between parties"
          },
          {
            bullet:
              "Promote a friendly, collaborative climate in teams or organisations"
          }
        ]
      },
      {
        id: "101",
        title: "Compassion",
        content:
          "You demonstrate a deep and genuine concern for the well-being and welfare of others",
        iconCode: "Compassion",
        description:
          "I will always notice when someone in the team is struggling and reach out to see if there is anything I can do to help. I am always supportive of others and try to put them at ease",
        bullets: [
          {
            bullet:
              "Are concerned with the general well-being and welfare of others"
          },
          { bullet: "Show kindness to others in times of need or crisis" },
          { bullet: "Put others’ needs ahead of your own" }
        ]
      },
      {
        id: "102",
        title: "Developing others",
        content:
          "You promote other people’s learning and development to help them achieve their goals and fulfil their potential ",
        iconCode: "DO",
        description:
          "I gain great energy from seeing other people develop and achieve their goals. This is particularly the case when I have been able to help them in the process",
        bullets: [
          {
            bullet:
              "Believe that people should continue to seek out opportunities to learn and grow throughout their lives"
          },
          {
            bullet:
              "Quickly recognise others’ strengths and potential and help them optimise these"
          },
          {
            bullet:
              "Are always on the lookout for opportunities to  develop, mentor and coach others to help them achieve their full potential"
          }
        ]
      },
      {
        id: "103",
        title: "Empathy",
        content:
          "You readily identify with other people’s situations and seeing things clearly from their perspective",
        iconCode: "Empathy",
        description:
          "I find myself automatically sensing what others are feeling. I ask questions to understand more and find it easy to put myself in other people’s shoes and understand why they do what they do",
        bullets: [
          {
            bullet:
              "Find it relatively easy to ‘put yourself in another's shoes’ and see things from their perspective"
          },
          {
            bullet:
              "Listen openly, without judging or interrupting, to understand a person’s underlying concerns, feelings and interests, rather than simply what they are saying"
          },
          {
            bullet:
              "Acknowledge and respect the unique experiences, perspectives and ideas of others"
          }
        ]
      },
      {
        id: "104",
        title: "Leading",
        content:
          "You take responsibility for influencing and motivating others to contribute to the goals and success of their team and organisation",
        iconCode: "Leading",
        description:
          "I feel alive and energized when I am motivating and engaging others towards a better future or ambitious goal",
        bullets: [
          {
            bullet:
              "Often find yourself being asked to speak on behalf of the group"
          },
          {
            bullet: "Find your colleagues frequently turn to you for direction"
          },
          {
            bullet:
              "Feel energised when you are given responsibility to  motivate a team to achieve its goals"
          }
        ]
      },
      {
        id: "105",
        title: "Persuasiveness",
        content:
          "You are able to win agreement and support for a position or desired outcome",
        iconCode: "Persuasiveness",
        description:
          "I am a natural persuader. I enjoy engaging others in debate. Winning agreement and support helps me to get others on board when we are changing direction and helps keep the motivation up",
        bullets: [
          {
            bullet:
              "Are able to persuade others to your way of thinking based on the merits of your position"
          },
          {
            bullet:
              "Enjoy negotiation and debate as they provide opportunity for you to state your case and win people over"
          },
          {
            bullet:
              "Find that there are times when you will stop at nothing to persuade and convince others"
          }
        ]
      },
      {
        id: "106",
        title: "Relationship building",
        content:
          "You take steps to build networks of contacts and act as a ‘hub’ between people that you know",
        iconCode: "RB",
        description:
          "I am energised by meeting new people. This helps me to build my network and find someone who knows the answer",
        bullets: [
          { bullet: "Have a wide network of colleagues and contacts" },
          { bullet: "Enjoy meeting new people and getting to know them" },
          {
            bullet:
              "Are the sort of person who knows everybody and often introduces people in your network to one another"
          }
        ]
      }
    ]
  },
  {
    id: 21,
    title: "Thinking Strengths",
    slogan: "How you collect information to solve problems (5 STRENGTHS)",
    list: [
      {
        id: "200",
        title: "Common sense",
        content:
          "You make pragmatic judgments based on practical thinking and previous experience",
        iconCode: "CS",
        description:
          "I seem to be able to see the straightforward way to deal with emergencies, complicated issues and vague ideas, turning them into workable solutions, often based on experience",
        bullets: [
          {
            bullet:
              "Make good judgments based on careful observation of what works in different situations"
          },
          { bullet: "Enjoy learning from experience" },
          {
            bullet:
              "Rely a lot on obvious and pragmatic answers that have worked well in the past"
          }
        ]
      },
      {
        id: "201",
        title: "Creativity",
        content:
          "You generate new ideas and original solutions to move things forward",
        iconCode: "Creativity",
        description:
          "I am always looking for an alternative angle on things, searching for new ideas. Often, I find ideas from other fields and think about how these can be applied for us",
        bullets: [
          {
            bullet:
              "Encourage others to explore new and creative perspectives when problem solving"
          },
          { bullet: "Enjoy coming up with new ideas and original solutions" },
          {
            bullet:
              "Have the ability to ‘think outside the box’ to find an original solution to a tough problem"
          }
        ]
      },
      {
        id: "202",
        title: "Critical thinking",
        content:
          "You approach problems and arguments by breaking them down systematically and evaluating them objectively ",
        iconCode: "CT",
        description:
          "When facing problems whether they are novel or familiar, I find it important to try and cut the problem up into its different parts and establish which areas need more attention than others",
        bullets: [
          {
            bullet:
              "Easily spot flaws in  arguments and problems based on logical analysis"
          },
          {
            bullet:
              "Enjoy bringing objectivity and clarity to complex situations"
          },
          {
            bullet:
              "Spend time defining and simplifying problems, understanding underlying assumptions, facts and evidence, before seeking solutions"
          }
        ]
      },
      {
        id: "203",
        title: "Detail orientation",
        content:
          "I like to focus on detail, and spotting mistakes or errors that others haven’t seen",
        iconCode: "DetailO",
        description:
          "It energises me to focus on details. This can mean that I pick up on mistakes that others have missed",
        bullets: [
          {
            bullet:
              "Strive for perfection in the quality of your work, no matter how small the task"
          },
          {
            bullet:
              "Check and willingly  redo work to ensure accuracy to meet others’ expectations and requests"
          },
          {
            bullet:
              "Take personal pride in the accuracy of your work, ensuring consistently high standards"
          }
        ]
      },
      {
        id: "204",
        title: "Strategic mindedness",
        content:
          "You focus on the future and take a strategic perspective on issues and challenges",
        iconCode: "SM",
        description:
          "I am drawn to thinking about the future, thinking about where we can go next, and what might be different. I enjoy looking out for new trends and ideas",
        bullets: [
          {
            bullet:
              "Demonstrate the ability to step above immediate concerns to see the 'big picture', spotting opportunities and risks for the organisation arising from its environment"
          },
          {
            bullet:
              "Oriented towards the long-term future and how the team, organisation and market landscape might look "
          },
          {
            bullet:
              "Enjoy predicting trends and scenarios of possible alternative futures and options for the organisation "
          }
        ]
      }
    ]
  },
  {
    id: 53,
    title: "Execution Strengths",
    slogan: "How you deliver results (6 STRENGTHS)",
    list: [
      {
        id: "300",
        title: "Decisiveness",
        content:
          "You make quick, confident, and clear decisions, even when faced with limited information",
        iconCode: "Decisiveness",
        description:
          "I love making quick decisions and moving on to the next thing; for me, decisiveness allows me to keep a quick pace and see immediate progress with what I’m working on",
        bullets: [
          {
            bullet:
              "Are willing to make decisions in high pressure situations when time is critical "
          },
          {
            bullet:
              "Are able to make effective and timely decisions even when the data is limited or decisions produce unpleasant consequences"
          },
          {
            bullet: "Quickly perceive the impact and implications of decisions"
          }
        ]
      },
      {
        id: "301",
        title: "Efficiency",
        content:
          "You take a well-ordered and methodical approach to tasks to achieve planned outcomes",
        iconCode: "Efficiency",
        description:
          "Processes, lists, plans, highlighters, red pens, black pens, Post-it notes and a watch: all essential tools for me. I find it energizing to organize things and find it the best way to get the most from my life",
        bullets: [
          { bullet: "Have efficient, well-ordered systems for working " },
          {
            bullet:
              "Are good at coordinating a complex range of tasks and people in order to achieve the best possible outcome"
          },
          {
            bullet:
              "Enjoy making action plans and lists detailing what needs to be done, by when and by whom"
          }
        ]
      },
      {
        id: "302",
        title: "Flexibility",
        content:
          "You remain adaptable and flexible in the face of unfamiliar or changing situations",
        iconCode: "Flexibility",
        description:
          "I like to take on new tasks regularly and feel motivated and energized working towards tight deadlines, often juggling a variety of different things at one time",
        bullets: [
          {
            bullet:
              "Find the idea of fast-moving and unpredictable situations energises you"
          },
          {
            bullet:
              "Are able to adapt relatively easily to whatever comes your way"
          },
          { bullet: "Embrace change in every aspect of your life" }
        ]
      },
      {
        id: "303",
        title: "Initiative",
        content:
          "You take independent action to make things happen and achieve goals",
        iconCode: "Initiative",
        description:
          "I remember reading an article about what successful people do and it suggested that they ‘blast into the white space between existing job descriptions’. If you want to achieve something, just do it",
        bullets: [
          {
            bullet:
              "Regularly solve problems or take action before being required or asked to do so"
          },
          {
            bullet:
              "Independently anticipate and deal with any problems or roadblocks to task completion"
          },
          {
            bullet:
              "Involve yourself early in the process to ensure that your input is included"
          }
        ]
      },
      {
        id: "304",
        title: "Results focus",
        content:
          "You maintain a strong sense of focus on results, driving tasks and projects to completion",
        iconCode: "RF",
        description:
          "I have a keen interest in achieving successful outcomes and delivering results. I don’t see the point in doing things half-heartedly or for no end result",
        bullets: [
          {
            bullet:
              "Convey a strong sense of urgency and drive issues to closure"
          },
          {
            bullet:
              "Take immediate action to resolve performance blockages or problems when they arise"
          },
          {
            bullet:
              "Maintain a strong focus on the goals of the organisation and the resources available to achieve those goals"
          }
        ]
      },
      {
        id: "305",
        title: "Self-improvement",
        content:
          "You draw on a wide range of people and resources in the pursuit of self-development and learning ",
        iconCode: "SI",
        description:
          "I feel like a sponge that just soaks up knowledge. The more I’m learning, the more satisfaction I get from my work and life. I take up new hobbies at least once a year",
        bullets: [
          {
            bullet:
              "Enjoy challenging yourself to learn new skills and behaviours that are completely new in order to develop  beyond your comfort zone"
          },
          {
            bullet:
              "Go out of your way to participate in developmental activities"
          },
          {
            bullet:
              "Put a great deal of time and effort into building skills and knowledge for the future"
          }
        ]
      }
    ]
  }
];

export const FetchFakeData = () => {
  return Data;
};

export default FetchFakeData;
