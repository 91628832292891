const EmailList = [
  "cl.hsbc.com",
  "firstdirect.com",
  "hangseng.com",
  "hsbc.at",
  "hsbc.bm",
  "hsbc.ca",
  "hsbc.co.id",
  "hsbc.co.in",
  "hsbc.co.jp",
  "hsbc.co.jp",
  "hsbc.co.mu",
  "hsbc.co.nz",
  "hsbc.co.th",
  "hsbc.co.uk",
  "hsbc.com",
  "hsbc.com.ar",
  "hsbc.com.au",
  "hsbc.com.bd",
  "hsbc.com.cn",
  "hsbc.com.co",
  "hsbc.com.hk",
  "hsbc.com.lk",
  "hsbc.com.mx",
  "hsbc.com.my",
  "hsbc.com.ph",
  "hsbc.com.sg",
  "hsbc.com.tr",
  "hsbc.com.tw",
  "hsbc.com.uy",
  "hsbc.com.vn",
  "hsbc.de",
  "hsbc.fr",
  "hsbcgroup.com",
  "hsbcib.com",
  "hsbcpb.com",
  "hsbcprivatebank.com",
  "hsbcqh.com.cn",
  "hsbctrinkaus.de",
  "hsi.com.hk",
  "inka-kag.de",
  "kr.hsbc.com",
  "lu.hsbc.com",
  "mandsbank.com",
  "noexternalmail.hsbc.com",
  "us.hsbc.com",
  "za.hsbc.com",
  "strengthscope.com",
  //Test
  "avamae.co.uk"
];

export const EmailValidation = () => {
  return EmailList;
};

export default EmailValidation;
