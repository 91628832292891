import React from "react";
import axios from "axios";
import { withLocalize, Translate } from "react-localize-redux";
import EmailValidation from "../apis/EmailValidation";
import * as helpers from "../Helpers";
import Config from "../Config";

export class Thanks extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      isChecked: false,
      reminders: false,
      invalidFields: [],
      reminderApplied: false,
      emailList: EmailValidation(),
      approved: false,
      loading: false
    };
    this.Email = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  reveiceReminderEmails = () => {
    axios
      .post(
        Config.url + "/api/v1/ConfirmChoices/ReceiveReminderEmails",
        {},
        helpers.axiosConfig()
      )
      .then(response => {
        if (response.data.status === "1") {
          this.setState({ reminderApplied: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  sendToManager = () => {
    if (this.fieldsValid() && !this.state.loader && !this.state.approved) {
      this.setState({ loading: true });
      axios
        .post(
          Config.url + "/api/v1/ConfirmChoices/SendToManager",
          {
            managerEmailAddress: this.Email.current.value
          },
          helpers.axiosConfig()
        )
        .then(response => {
          if (this._isMounted) {
            if (response.data.status === "1") {
              this.setState({ approved: true });
              this.Email.current.value = "";
            } else {
              this.setState({ invalidFields: response.data.errors });
            }

            this.setState({ loading: false });
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log(error);
        });
    }
  };

  fieldsValid = () => {
    let invalidFieldsList = [];

    if (this.Email.current.value.trim() === "") {
      invalidFieldsList.push({
        fieldName: "ManagerEmailAddress",
        messageCode: "Required"
      });
    }

    if (invalidFieldsList.length > 0) {
      this.setState({
        invalidFields: invalidFieldsList
      });

      return false;
    } else {
      this.setState({
        invalidFields: []
      });

      return true;
    }
  };

  render() {
    return (
      <div className="Thanks">
        <div className="Container HeightPadding">
          <div className="StructureTable">
            <div className="RightCell">
              <h1>
                <Translate id="ThankYou" />
              </h1>

              <p>
                <Translate id="Thanks_15" />
              </p>
              <p>
                <Translate id="Thanks_16" />
              </p>
              <p>
                <Translate id="Thanks_17" />
              </p>
              <div className="Signature">
                <Translate id="Thanks_18" />
              </div>
              {/*
              <p>
                <Translate id="Thanks_01" />
              </p>

              <p>
                <Translate id="Thanks_02" />{" "}
                <u>
                  <a href="mailto:help@strengthscope.com ">
                    <Translate id="Thanks_03" />
                  </a>
                </u>
              </p>


              <div className="CheckboxRow">
                <input
                  ref
                  className="Checkbox"
                  id="ischecked"
                  type="checkbox"
                  value="ischecked"
                  onChange={() =>
                    this.setState({ isChecked: !this.state.isChecked })
                  }
                />
                <label htmlFor="ischecked">
                  <Translate id="Thanks_04" />
                </label>
              </div>

              {this.state.isChecked ? (
                <div className="CheckboxHideShow">
                  <div className="Form Form2">
                    <div
                      className={
                        "FormBox " +
                        (helpers.fieldValid(
                          "ManagerEmailAddress",
                          this.state.invalidFields
                        ) === true
                          ? ""
                          : "Highlighted")
                      }
                    >
                      <div className="FormLabel">
                        <label htmlFor="Email">
                          <Translate id="ManagerHSBCEmailAddress" />
                        </label>
                      </div>
                      <div
                        className={
                          "FormField" +
                          (this.state.approved ? " Disabled" : "")
                        }
                      >
                        <span className="Icon Icon_03" />
                        <input
                          ref={this.Email}
                          type="email"
                          maxLength="50"
                          name="email"
                          id="emailAddress"
                          className="Input"
                        />
                        <span className="ResponseMessage">
                          <Translate
                            id={helpers.fieldErrorCode(
                              "ManagerEmailAddress",
                              this.state.invalidFields
                            )}
                          />
                        </span>
                      </div>
                      <div className="FormButton">
                        {!this.state.approved ? (
                          <div className="Btn BtnRed BtnSend">
                            <span onClick={() => this.sendToManager()}>
                              {!this.state.loading ? (
                                <Translate id="Send" />
                              ) : (
                                <div className="Loader" />
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className="Btn BtnRed BtnSend BtnSent">
                            <span>
                              <div className="Icon" />
                              <Translate id="Sent" />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                        ) : null} 

              <div className="LinkRow">
                <u
                  onClick={() =>
                    this.setState({ reminders: !this.state.reminders })
                  }
                >
                  <Translate id="Thanks_05" />
                </u>{" "}
                <Translate id="Thanks_06" />
              </div>

              {this.state.reminders ? (
                <div className="LinkRowHideShow">
                  <div className="Table">
                    <div className="CellOne">
                      {this.state.reminderApplied ? (
                        <Translate id="Thanks_11" />
                      ) : (
                        <Translate id="Thanks_10" />
                      )}
                    </div>
                    <div className="CellTwo">
                      {!this.state.reminderApplied ? (
                        <div
                          className="Btn BtnConfirm"
                          onClick={() => this.reveiceReminderEmails()}
                        >
                          <span>
                            <span className="BtnImgLeft" />
                            <Translate id="Confirm" />
                            <span className="BtnImgRight" />
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="LineSpacer3" />
              */}

            </div>

            <div className="LeftCell">
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withLocalize(Thanks);
