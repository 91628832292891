import React from "react";
import Tooltip from "rc-tooltip";
import { withLocalize, Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import StrengthsCard from "./StrengthsCard";
import Swiper from "react-id-swiper";
import "swiper/dist/css/swiper.min.css";

import FetchFakeData from "../apis/StrengthsApis";

import Circle from "./BaseUI/circle";
import SelectedStrengths from "./BaseUI/selectedStrengths";

export class Strengths extends React.Component {
  displayName = Strengths.name;

  constructor(props) {
    super(props);

    this.state = {
      // Fake data
      categories: [],
      selectedItems: [],
      selectedColor: null,
      selectedCategory: null,
      wheelIsAnimated: false,
      tooltip: ["false", "false", "false"],
      tooltipOverlay: false,
      delayBetweenCards: 80,
      allowAdd: true
    };

    if (sessionStorage.getItem("tooltips") !== null) {
      var savedTooltipsData = sessionStorage.getItem("tooltips").split(",");
      this.state.tooltip = savedTooltipsData;
      var findTrue = savedTooltipsData.indexOf("true");
      if (findTrue === -1) {
        this.state.tooltipOverlay = false;
        this.state.disableDragging = false;
      }
    }

    if (this.props.selectedItems.length !== 0) {
      this.state.selectedItems = this.props.selectedItems;
    }
  }

  componentDidMount() {
    this.props.fixedHeader(true);
    this.props.instructions(true);
    this.getData();
  }

  componentWillUnmount() {
    this.props.fixedHeader(false);
    this.props.instructions(false);
  }

  getData = () => {
    this.setState({
      categories: FetchFakeData()
    });
  };

  handleColor = result => {
    if (this.state.selectedColor === result) {
      this.setState({
        wheelIsAnimated: false,
        selectedColor: null,
        delayBetweenCards: 80
      });
    } else {
      this.setState(
        {
          selectedColor: result,
          wheelIsAnimated: true,
          categories: [],
          delayBetweenCards: 80
        },
        function () {
          this.getData();
          setTimeout(() => {
            this.setState({ loaded: true });
          }, 600);
          this.setState({ wheelIsAnimated: true });
        }
      );
    }
  };

  removeColor = () => {
    this.setState({
      wheelIsAnimated: false,
      selectedColor: null
    });
  };

  selectCard = id => {
    this.setState({ allowAdd: false })
    if (this.state.allowAdd) {
      let categories = this.state.categories;
      let selectedItems = this.state.selectedItems;

      if (selectedItems.length < 5) {
        if (!selectedItems.find(x => x.id === id)) {
          let selected = categories[this.state.selectedColor].list.find(
            x => x.id === id
          );
          selected = {
            ...selected,
            color: this.state.selectedColor,
            categoryIndex: this.state.selectedColor,
            index: categories[this.state.selectedColor].list.indexOf(selected)
          };
          selectedItems.push(selected);
          categories[this.state.selectedColor].list.find(
            x => x.id === id
          ).disabled = true;
          this.setState({
            selectedItems,
            categories,
            delayBetweenCards: 0
          });
          this.props.selectItems(selectedItems);
        }
      }
    }
    this.setState({ allowAdd: true })
  };

  removeCard = (category, index, id) => {
    let categories = this.state.categories;
    if (categories[category].list.find(x => x.id === id)) {
      let selectedItems = this.state.selectedItems;
      categories[category].list.find(x => x.id === id).disabled = false;
      let filter = selectedItems.filter((x, i) => x.id !== id);
      this.setState({
        categories: categories,
        selectedItems: filter
      });
      this.props.selectItems(filter);
    }
  };

  tooltip = index => {
    // Tooltips checking and setting to local storage
    var visibleTooltips = this.state.tooltip;
    visibleTooltips.splice(index, 1, "false");

    this.setState({ tooltip: visibleTooltips }, function () {
      sessionStorage.setItem("tooltips", this.state.tooltip);
      var overlay = visibleTooltips.indexOf("true");

      if (overlay === -1) {
        this.setState({
          tooltipOverlay: false,
          disableDragging: false
        });
      }
    });
  };

  render() {
    var categories = this.state.categories;
    const swiperConfig = {
      // Disable swiping for desktop
      shouldSwiperUpdate: true,
      noSwiping: true,
      loop: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true
      },
      slidesPerView: "auto",
      breakpoints: {
        1440: {
          noSwiping: false
        },
        768: {
          //slidesPerView: 2,
          noSwiping: false
        },
        500: {
          //slidesPerView: 1,
          noSwiping: false
        }
      }
    };

    return (
      <div
        className={
          "Strengths" +
          (this.state.selectedColor !== null
            ? " Color" + this.state.selectedColor
            : " None")
        }
      >

        <div className="StrengthsTable">
          <div className="StrengthsContent">
            <div className="StrengthsBlock">


              <div className="StrengthsInner">
                {this.state.selectedColor !== null && this.state.selectedColor !== undefined && 
                  this.state.categories.length !== 0 ? (
                    <div className="CardsIntro">
                      <div className="CardsTitle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18px"
                          height="30px"
                          transform="translate(0 4)"
                        >
                          <path
                            fillRule="evenodd"
                            opacity="0.502"
                            fill="rgb(126, 132, 126)"
                            d="M2.414,3.188 C1.428,3.188 0.976,3.635 0.976,4.616 L0.976,27.598 C0.976,28.578 1.428,29.025 2.414,29.025 L15.458,29.025 C16.445,29.025 16.896,28.578 16.896,27.598 L16.896,4.616 C16.896,3.635 16.445,3.188 15.458,3.188 L12.917,3.188 L12.777,2.980 C11.753,1.460 10.636,1.256 8.806,1.256 C6.976,1.256 5.976,1.460 4.953,2.980 L4.813,3.188 L2.414,3.188 ZM15.458,29.967 L2.414,29.967 C0.906,29.967 0.030,29.097 0.030,27.598 L0.030,4.616 C0.030,3.116 0.906,2.248 2.414,2.248 L4.313,2.248 C5.524,0.592 6.744,0.314 8.806,0.314 C10.868,0.314 12.206,0.592 13.418,2.248 L15.458,2.248 C16.967,2.248 17.843,3.116 17.843,4.616 L17.843,27.598 C17.843,29.097 16.967,29.967 15.458,29.967 "
                          />
                          <path
                            fillRule="evenodd"
                            className="Lightning"
                            d="M9.378,0.761 L0.895,11.330 L4.558,11.330 L2.376,19.867 L10.858,9.297 L7.195,9.297 L9.378,0.761 Z"
                            transform="translate(3 6)"
                          />
                        </svg>
                        {categories[this.state.selectedColor].title}
                      </div>
                      <div className="CardsSubtitle">
                        {categories[this.state.selectedColor].slogan}
                      </div>
                    </div>
                  ) : null}

                <Circle
                  animated={this.state.wheelIsAnimated}
                  color={this.state.selectedColor}
                  changeColor={this.handleColor}
                  state={() =>
                    this.setState({
                      wheelIsAnimated: !this.state.wheelIsAnimated
                    })
                  }
                />

                {/* Cards List  */}
                {this.state.selectedColor !== null &&
                  this.state.categories.length !== 0 ? (
                    <div className="CardsList">
                      <div className="Row" id={this.state.droppableId}>
                        <Swiper {...swiperConfig}>
                          {categories[this.state.selectedColor].list.map(
                            (single, index) => {
                              var cardDetails = this.state.categories[
                                this.state.selectedColor
                              ].list.find(item => item.id === single.id);
                              if (!single.disabled) {
                                return (
                                  <div className="Slide" key={index}>
                                    <StrengthsCard
                                      cardDetails={cardDetails}
                                      singleId={single.id}
                                      selectedColor={this.state.selectedColor}
                                      addManual={this.addManual}
                                      tooltip={this.tooltip}
                                      visibleTooltips={this.state.tooltip}
                                      index={index}
                                      delay={
                                        (index + 1) * this.state.delayBetweenCards
                                      }
                                      openPopup={this.props.openPopup}
                                      selectCard={this.selectCard}
                                      requireNewPosition={
                                        this.props.requireNewPosition
                                      }
                                      newPosition={this.props.newPosition}
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                        </Swiper>
                      </div>
                      <div className="Exit" onClick={this.removeColor} />
                    </div>
                  ) : null}
              </div>

              <SelectedStrengths
                selectedStrengths={this.state.selectedItems}
                removeCard={this.removeCard}
              />

              <div className="ButtonsBottom">
                {this.state.selectedItems.length === 5 ? (
                  <Link to="/confirm">
                    <div className="Btn BtnApply">
                      <span>
                        <Translate id="Select" />
                      </span>
                    </div>
                  </Link>
                ) : (
                    <div className="Btn BtnApply BtnDisabled">
                      <span>
                        <Translate id="Select" />
                      </span>
                    </div>
                  )}
              </div>

            </div>
            {this.state.selectedColor !== null ? (
              this.state.tooltipOverlay ? (
                <div className="TooltipOverlay" />
              ) : null
            ) : null}
          </div>
        </div>

      </div>
    );
  }
}
export default withLocalize(Strengths);
