import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';

export class NotFound extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="NotFound">
        <img src={require('../images/Icon_Warning.png')} alt="NotFound" />
        <h2><Translate id="NotFound_01" /></h2>
        <p><Link to="/"><Translate id="NotFound_02" /></Link></p>
      </div>
    );
  }
}

export default NotFound
