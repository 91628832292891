import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import StrengthsIcons from "../StrengthsIcons";
import { StrengthsCard } from "../StrengthsCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export class MoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      styles: {
        left: this.props.data.positions.left,
        top: this.props.data.positions.top,
        width: this.props.data.positions.width,
        height: this.props.data.positions.height
      },
      front: {
        opacity: 1
      },
      back: {
        opacity: 0
      }
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.updateCalculation();
    });
    let width = window.innerWidth < 480 ? window.innerWidth : 480;
    let height = 600;
    setTimeout(() => {
      this.setState({
        styles: {
          left: window.innerWidth / 2 - width / 2,
          top:
            window.innerHeight > 600 ? window.innerHeight / 2 - height / 2 : 0,
          width: width,
          height: height,
          transform:
            window.innerHeight > 600 ? "rotateY(-540deg)" : "rotateY(-540deg)"
        }
      });
    }, 300);

    setTimeout(() => {
      this.setState({
        back: {
          opacity: 1
        },
        front: {
          opacity: 0
        }
      });
    }, 550);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  updateCalculation = () => {
    let width = window.innerWidth < 480 ? window.innerWidth : 480;
    let height = 600;
    this.setState({
      styles: {
        ...this.state.styles,
        left: window.innerWidth / 2 - width / 2,
        top: window.innerHeight > 600 ? window.innerHeight / 2 - height / 2 : 0,
        height: window.innerHeight < 600 ? window.innerHeight : 600,
        width: width
      }
    });
  };

  closeModal = () => {
    //this.props.requireNewPosition();
    //if (this.props.newPosition !== null) {
    this.setState(
      {
        styles: {
          left: this.props.data.positions.left, // Unfinished this.props.newPosition. It will get current possition of original element ( if was resized to find it's place ). Unfinished Need to add index
          top: this.props.data.positions.top,
          width: this.props.data.positions.width,
          height: this.props.data.positions.height
        }
      },
      function() {
        setTimeout(() => {
          this.props.closePopup();
        }, 480);
      }
    );

    setTimeout(() => {
      this.setState({
        back: {
          opacity: 0
        },
        front: {
          opacity: 1
        }
      });
    }, 150);
    //}
  };

  render() {
    let data = this.props.data;
    return (
      <div className={"MoreInfo Color" + this.props.data.color}>
        <div
          style={{
            ...this.state.styles
          }}
          className="CardFlip"
        >
          <div className="CardFront" style={this.state.front}>
            <StrengthsCard
              cardDetails={this.props.data.cardDetails}
              singleId={this.props.data.singleId}
              selectedColor={this.props.data.color}
              index={this.props.data.index}
              delay={(this.props.data.index + 1) * 100}
              openPopup={this.props.openPopup}
            />
          </div>
          <PerfectScrollbar
            style={{
              ...this.state.back,
              height: window.innerHeight < 600 ? window.innerHeight : 600
            }}
          >
            <div className="CardBack" style={this.state.back}>
              <StrengthsIcons code={data.iconCode} imageSize="big">
                <div className="CloseModal" onClick={this.closeModal}>
                  <svg
                    id="icons"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 153.9 153.9"
                    fill="#ffffff"
                  >
                    <rect
                      x="164.2"
                      y="165.8"
                      width="188.8"
                      height="28.8"
                      transform="translate(-233.3 132.4) rotate(-45)"
                    />
                    <rect
                      x="164.2"
                      y="165.8"
                      width="188.8"
                      height="28.8"
                      transform="translate(21.5 -233.3) rotate(45)"
                    />
                  </svg>
                </div>
                <div className="CardsTitle">{data.title}</div>
                <div className="CardsSubTitle">{data.content}</div>
                <div className="CardsDescription">
                  <Translate id="MoreInfo_01" />:
                </div>
                <ul className="CardsList">
                  {data.bullets.map((bullet, index) => {
                    return <li key={index}> {bullet.bullet} </li>;
                  })}
                </ul>
                <div className="CardsDescription">
                  <Translate id="ForExample" />:
                </div>
                <div className="CardsText">{data.description}</div>
              </StrengthsIcons>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

export default withLocalize(MoreInfo);
