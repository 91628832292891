import React from "react";
import { withLocalize, Translate } from "react-localize-redux";

import StrengthsIcons from "../StrengthsIcons";

export class SelectedStrengths extends React.Component {
  render() {
    let selectedStrengths = this.props.selectedStrengths;
    return (
      <div className="TopStrengths">
        <div className="Container">
          <div className="Table">
            <div className="Cell">

              <div className="PickedCards">
                {/* Cards holders. */}
                <div className="Card" />
                <div className="Card" />
                <div className="Card" />
                <div className="Card Disabled" />
                <div className="Card Disabled" />

                {/* Selected cards */}
                <div className="NewCards">
                  {selectedStrengths.length !== 0
                    ? selectedStrengths.map((x, i) => (
                      <div className={"Card Color" + x.color} key={i}>
                        <div
                          className="RemoveCard"
                          onClick={() =>
                            this.props.removeCard(
                              x.categoryIndex,
                              x.index,
                              x.id
                            )
                          }
                        >
                          <svg
                            id="icons"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 153.9 153.9"
                          >
                            <rect
                              x="164.2"
                              y="165.8"
                              width="188.8"
                              height="28.8"
                              transform="translate(-233.3 132.4) rotate(-45)"
                            />
                            <rect
                              x="164.2"
                              y="165.8"
                              width="188.8"
                              height="28.8"
                              transform="translate(21.5 -233.3) rotate(45)"
                            />
                          </svg>
                        </div>
                        <div className="CardIcon">
                          <StrengthsIcons
                            code={x.iconCode}
                            imageSize="small"
                            type="image"
                          />
                        </div>
                        <div className="Title">
                          <div className="CellSmall">{x.title}</div>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </div>

              <p className="TSText"><Translate id="Strengths_12" /></p>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLocalize(SelectedStrengths);
