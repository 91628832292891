import React from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";
import StrengthsIcons from "./StrengthsIcons";
import * as helpers from "../Helpers";
import Config from "../Config";

export class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: false,
      loading: false
    };
  }

  confirm = () => {
    if (!this.state.loading) {
      let choices = [];
      this.props.selectedItems.map(x => {
        choices.push(x.title);
      });
      this.setState({ loading: true });
      axios
        .post(
          Config.url + "/api/v1/ConfirmChoices/Confirm",
          {
            listChoices: choices
          },
          helpers.axiosConfig()
        )
        .then(response => {
          if (response.data.status === "1") {
            this.setState({ confirmed: true, loading: false });
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log(error);
        });
    }
  };

  render() {
    return (
      <div className="Confirm">
        <div className="Container HeightPadding">
          <div className="StructureTable">
            <div className="RightCell">
              <h1>
                <Translate id="ConfirmChoices" />
              </h1>

              <p>
                <Translate id="Confirm_01" />
              </p>

              <div className="SelectedCards">
                {this.props.selectedItems.length !== 0 ? (
                  this.props.selectedItems.map((single, index) => {
                    return (
                      <div
                        className={"Card Color" + single.color}
                        key={index}
                      >
                        <StrengthsIcons
                          code={single.iconCode}
                          imageSize="small"
                          type="image"
                        />
                        <div className="CardTitle">
                          <div className="CellSmall">{single.title}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="Card" />
                )}
              </div>

              <p>
                <Translate id="Confirm_02" />
              </p>

            </div>

            <div className="LeftCell LeftCellConfirmPage">
              <div className="ButtonsConfirmPage">
                <div
                  className={
                    "Btn BtnConfirm" + (this.state.loading ?
                      " NoBackground" : "")
                  }
                >
                    <span onClick={() => this.confirm()}>
                      {!this.state.loading ? (
                        <Translate id="Confirm_04" />
                      ) : (
                          <div>
                            Generating your guide
                                <div className="Loader" />
                          </div>
                        )}
                    </span>

                </div>
                <div className="Btn BtnChange">
                  <Link to="/strengths">
                    <Translate id="Confirm_03" />
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
        {this.state.confirmed ? <Redirect to="/thanks" /> : null}
      </div>
    );
  }
}
export default withLocalize(Confirm);
