export const axiosCallConfig = {
  timeout: 60000 //default 60000 (60 sec)
};

export function fieldValid(filedName, invalidFieldsList) {
  return (
    invalidFieldsList.find(
      item => item.fieldName.toLowerCase() === filedName.toLowerCase()
    ) === undefined
  );
}

export function fieldErrorCode(filedName, invalidFieldsList) {
  //list of erroneous fields with codes will be in array created by front end or back end validation
  //console.log ('filedName', filedName, 'invalidFieldsList', invalidFieldsList);
  let foundCode = invalidFieldsList.find(
    item => item.fieldName.toLowerCase() === filedName.toLowerCase()
  );
  if (foundCode === undefined) {
    return "";
  } else {
    //console.log ('foundCode.messageCode',foundCode.messageCode)
    return foundCode.messageCode; //false - field invalid (in the list)
  }
}

export function getAccessToken() {
  return localStorage.getItem("at");
}

export function setTokensWithTimes(accessToken, refreshToken, duration) {
  //saves tokens and refresh/expiry times, save in local storage because remember me may be selected and storage values are needed after tab close

  //let decodedToken = decodeToken(accessToken); //no need to decode token at this point
  //console.log ('decodedToken', decodedToken);

  localStorage.setItem("at", accessToken); //"at" - access token
}

export function axiosConfig() {
  //let fullConfig = config.axiosConfig; // <- this creates reference to the same object!
  let fullConfig = cloneObject(axiosCallConfig);
  fullConfig["headers"] = { Authorization: "Bearer " + getAccessToken() };
  return fullConfig;
}

export function cloneObject(src) {
  //warning: shalow clone only (not for nested objects)
  return Object.assign({}, src);
}
