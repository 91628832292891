import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export class Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      styles: {
        left: this.props.positions.left,
        top: this.props.positions.top,
        width: this.props.positions.width,
        height: this.props.positions.height,
        borderRadius: "50%",
        overflow: "hidden"
      },
      container: {
        opacity: 0,
        width: this.props.positions.width,
        borderRadius: "50%"
      },
      button: {},
    };

    this.Container = React.createRef();
  }

  componentDidMount() {
    this.updateCalculation(300, 600);
    window.addEventListener("resize", () => {
      this.updateCalculation(0, 0);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => { });
  }

  updateCalculation = (anim1, anim2) => {
    let width = 600;
    let height = 760;
    let query = window.innerHeight
    let query2 = window.innerWidth

    if ((query < 720 && query2 > 700) && query > 600) {
      width = 700
      height = 600
    } else if ((query < 600 && query2 > 700) && query > 400) {
      height = 400
      width = 700
    } else if (query < 400 && query2 > 400) {
      height = query
      width = window.innerWidth
    } else if (query2 < 490) {
      width = window.innerWidth
    }

    setTimeout(() => {
      this.setState({
        styles: {
          left: window.innerWidth / 2 - width / 2,
          top: window.innerHeight > height ? window.innerHeight / 2 - height / 2 : 0,
          height: height,
          width: width,
          borderRadius: 3,
          overflow: "hidden"
        },
        container: {
          borderRadius: 3
        }
      });
    }, anim1);

    setTimeout(() => {
      this.setState({
        container: {
          opacity: 1
        },
        button: {
          opacity: 0
        }
      });
    }, anim2);
  };

  closeModal = () => {
    this.props.requireNewPosition()
    this.setState({
      styles: {
        left: this.props.positions.left,
        top: this.props.positions.top,
        width: this.props.positions.width,
        height: this.props.positions.height,
        borderRadius: "50%",
        overflow: "hidden"
      },
      container: {
        opacity: 0,
        width: this.props.positions.width,
        height: this.props.positions.height,
        borderRadius: "50%",
        background: "transparent"
      },
      button: {
        opacity: 1,
        zIndex: 2
      }
    });

    setTimeout(() => {
      this.setState({
        styles: {
          left: this.props.positions.left,
          top: this.props.positions.top,
          width: this.props.positions.width,
          height: this.props.positions.height,
          borderRadius: "50%",
          overflow: "hidden"
        }
      });
    }, 100);

    setTimeout(() => {
      this.props.closePopup();
    }, 300);
  };

  handleNext = () => {
    this.closeModal();
  };

  render() {
    return (
      <div
        className="Instructions"
        style={{
          ...this.state.styles
        }}
      >
        <div
          className="FakeButton"
          style={{
            ...this.state.button
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.9 773.9">
            <path
              d="M646.4,163.9a194.5,194.5,0,0,0-50-66.5q-32.1-28.1-77.1-43.6T419.9,38.3c-34.3,0-65.8,5.7-94.7,17A229.2,229.2,0,0,0,249.3,104q-32.6,31.6-52.9,76.7c-2.1,4.6-4,9.4-5.9,14.2-16,41.8,14.9,86.6,59.6,86.6h6.5c27.8,0,52.7-17.7,61.1-44.2q27.3-85.8,103.2-85.8c15.8,0,30.2,2.5,43.3,7.7a97.8,97.8,0,0,1,33.6,21.5,100.1,100.1,0,0,1,22,32.3,102.7,102.7,0,0,1,7.9,40.8,136.5,136.5,0,0,1-1.7,22c-1.2,6.8-4.2,14.1-9.2,21.8s-12.3,16.6-22,26.7-23,22.2-39.8,36.3q-58.3,48.4-80.6,94-11.4,23.3-16.5,62.8c-1.2,8.6-2.1,17.8-2.9,27.6a63.7,63.7,0,0,0,63.6,68.8h0A63.9,63.9,0,0,0,482.2,555c1.8-22.9,5-39.1,9.4-48.5s10.9-18.1,21.8-29.4,25.2-24.5,43-39.3q61.8-51,84-94.5c15.9-28.6,23.8-60.5,23.8-95.4C664.2,217.6,658.2,189.6,646.4,163.9Z"
              transform="translate(-186.2 -38.3)"
            />
            <path
              d="M420.2,664.1h-2.1c-40.3,0-72.9,33.1-72.9,74h0c0,40.9,32.6,74,72.9,74h2.1c40.3,0,73-33.1,73-74h0C493.2,697.2,460.5,664.1,420.2,664.1Z"
              transform="translate(-186.2 -38.3)"
            />
          </svg>
        </div>
        <PerfectScrollbar
          style={{
            height: '101%'
          }}
        >
          <div
            ref={this.Container}
            className="PopupContainer"
            style={{
              ...this.state.container
            }}
          >
            <div className="CloseModal" onClick={() => this.closeModal()}>
              <svg
                id="icons"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 153.9 153.9"
                fill="#787E80"
              >
                <rect
                  x="164.2"
                  y="165.8"
                  width="188.8"
                  height="28.8"
                  transform="translate(-233.3 132.4) rotate(-45)"
                />
                <rect
                  x="164.2"
                  y="165.8"
                  width="188.8"
                  height="28.8"
                  transform="translate(21.5 -233.3) rotate(45)"
                />
              </svg>
            </div>

            {/*
            <div className="Image">
              <img src={require("../../images/instructions01.jpg")} alt="" />
            </div>
            */}

            <div className="Title">
              <Translate id="Instructions" />
            </div>
            <div className="">
              <div className="Subtitle">
                <Translate id="Instructions_Title_01" />
              </div>
              <div className="FullInfo">
                <Translate id="Instructions_Info_01" />
              </div>
            </div>
            <div className="">
              <div className="Subtitle">
                <Translate id="Instructions_Title_02" />
              </div>
              <div className="FullInfo">
                <Translate id="Instructions_Info_02" />
              </div>
            </div>
            <div className="">
              <div className="Subtitle">
                <Translate id="Instructions_Title_03" />
              </div>
              <div className="FullInfo">
                <Translate id="Instructions_Info_03" />
              </div>
            </div>
            <div className="">
              <div className="Subtitle">
                <Translate id="Instructions_Title_04" />
              </div>
              <div className="FullInfo">
                <Translate id="Instructions_Info_04" />
              </div>
            </div>

            {/*
            <div className="PopUpButton">
              <div className="Btn" onClick={() => this.handleNext()}>
                <span>
                  <span className="BtnImgLeft" />

                      <Translate id="Close" />

                  <span className="BtnImgRight" />
                </span>
              </div>
            </div>
            */}

          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default withLocalize(Instructions);
