import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";
import HttpsRedirect from "react-https-redirect";
import "./styles/General.css";
import "./styles/MediaQueries.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://ef8c2e6e15474b7faf24e5f71b72f3aa@sentry.io/1337765"
});

window._mfq = window._mfq || [];
(function() {
  var mf = document.createElement("script");
  mf.type = "text/javascript";
  mf.async = true;
  mf.src =
    "//cdn.mouseflow.com/projects/6c025605-de1c-43ba-85e9-cbedfc9f4e8e.js";
  document.getElementsByTagName("head")[0].appendChild(mf);
})();
//window._mfq.push(["setVariable", "KEY", "VALUE"]);

ReactDOM.render(
  <HttpsRedirect>
    <LocalizeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LocalizeProvider>
  </HttpsRedirect>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
