import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

export class Welcome extends React.Component {
  render() {
    return (
      <div className="Welcome">
        <div className="Container HeightPadding">
          <div className="StructureTable">
            <div className="RightCell">
              <p className="Intro">
                <Translate id="Welcome_02" />
              </p>
              <p>
                <Translate id="Welcome_03" />
              </p>
              <p>
                <Translate id="Welcome_04" />
              </p>
              <p>
                <Translate id="Welcome_05" />
              </p>

              <div className="Btn BtnWelcomePage">
                <Link to="/register">
                  <Translate id="Register" />
                </Link>
              </div>

            </div>

            <div className="LeftCell">

              <div className="VideoIntro">
                <div className="VideoCell">
                  <div className="VideoText">What are strengths</div>
                  <div className="VideoWrapper">
                    <iframe
                      src="https://player.vimeo.com/video/333521722?title=0&byline=0&portrait=0"
                      allowfullscreen="allowfullscreen"
                      mozallowfullscreen="mozallowfullscreen"
                      msallowfullscreen="msallowfullscreen"
                      oallowfullscreen="oallowfullscreen"
                      webkitallowfullscreen="webkitallowfullscreen"
                      width="560"
                      height="315"
                    />
                  </div>
                </div>
              </div>

            </div>

          </div>



        </div>
      </div>
    );
  }
}
export default withLocalize(Welcome);
