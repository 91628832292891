import React from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';

export class TermsAndConditions extends React.Component {
  render() {
    return (
      <div className="TermsAndConditions">

        <div className="Container HeightPadding">

          <h1><Translate id="TermsAndConditions" /></h1>

          <p><Translate id="StrengthsPartnerTitle" /></p>

          <p><Translate id="TAC_01" /></p>
          <p><Translate id="TAC_01_1_1" /></p>
          <p><Translate id="TAC_01_1_2" /></p>
          <p><Translate id="TAC_01_2" /></p>
          <p><Translate id="TAC_01_3" /></p>
          <p><Translate id="TAC_01_4" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_02" /></p>
          <p><Translate id="TAC_02_1" /></p>
          <p><Translate id="TAC_02_2" /></p>
          <ul>
            <li><Translate id="TAC_02_List_1" /></li>
            <li><Translate id="TAC_02_List_2" /></li>
            <li><Translate id="TAC_02_List_3" /></li>
            <li><Translate id="TAC_02_List_4" /></li>
          </ul>
          <p><Translate id="TAC_02_3" /></p>
          <p><Translate id="TAC_02_4" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_03" /></p>
          <p><Translate id="TAC_03_1" /></p>
          <p><Translate id="TAC_03_2" /></p>
          <p><Translate id="TAC_03_3" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_04" /></p>
          <p><Translate id="TAC_04_1" /></p>
          <p><Translate id="TAC_04_2" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_05" /></p>
          <p><Translate id="TAC_05_1" /></p>
          <p><Translate id="TAC_05_2_1" /></p>
          <p><Translate id="TAC_05_2_2" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_06" /></p>
          <p><Translate id="TAC_06_1" /></p>
          <p><Translate id="TAC_06_2" /></p>
          <p><Translate id="TAC_06_3" /></p>
          <p><Translate id="TAC_06_4" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_07" /></p>
          <p><Translate id="TAC_07_1" /></p>
          <p><Translate id="TAC_07_2" /></p>
          <p><Translate id="TAC_07_3" /></p>
          <p><Translate id="TAC_07_4" /></p>
          <p><Translate id="TAC_07_5" /></p>
          <p><Translate id="TAC_07_6" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_08" /></p>
          <p><Translate id="TAC_08_1_1" /></p>
          <p><Translate id="TAC_08_1_2" /></p>
          <p><Translate id="TAC_08_1_3" /></p>
          <p><Translate id="TAC_08_1_4" /></p>
          <p><Translate id="TAC_08_2" /></p>
          <p><Translate id="TAC_08_3" /></p>
          <p><Translate id="TAC_08_4_1" /></p>
          <ul>
            <li><Translate id="TAC_04_List_1" /></li>
            <li><Translate id="TAC_04_List_2" /></li>
          </ul>
          <p><Translate id="TAC_08_4_2" /></p>

          <div className="LineSpacer6"></div>

          <p><Translate id="TAC_DocLastUpdated" /></p>

          <p><Translate id="CopyrightStrengths" /></p>

          <div class="LineSpacer3"></div>

          <div className="BottomLinks">
            <div className="Btn BtnRight">
              <Link to="/register">
                <Translate id="Register" />
              </Link>
            </div>
            <div className="BottomFix" />
          </div>

        </div>

      </div>
    );
  }
}
export default withLocalize(TermsAndConditions);