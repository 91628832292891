import React from "react";
import axios from "axios";
import { withLocalize, Translate } from "react-localize-redux";
import * as helpers from "../Helpers";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Config from "../Config";

export class Register extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      invalidFields: [],
      alreadyRegistered: false,
      registered: false,
      loading: false,
      reloading: false,
      displayLoginForm: true
      //emailList: EmailValidation()
    };

    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.emailAddress = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleForm = () => {
    if (this.formValid() && !this.state.loading) {
      this.setState({ loading: true });
      axios
        .post(Config.url + "/api/v1/Registration/Register", {
          firstName: this.firstName.current.value,
          lastName: this.lastName.current.value,
          emailAddress: this.emailAddress.current.value,
          language: this.props.language
        })
        .then(response => {
          if (this._isMounted) {
            if (response.data.status === "1") {
              this.getToken(true);
              this.setState({ alreadyRegistered: false, registered: true, invalidFields: [] });
            } else if (response.data.status === "2") {
              this.setState({ alreadyRegistered: true, registered: false, invalidFields: [], displayLoginForm: false });
            } else {
              this.setState({ alreadyRegistered: false, registered: false, invalidFields: response.data.errors });
            }
            this.setState({ loading: false });
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log(error);
        });
    }
  };

  redownloadReport = () => {
    if (!this.state.reloading) {
      this.getToken(false);
      this.setState({ reloading: true });

      axios
        .get(
          Config.url + "/api/v1/ConfirmChoices/sendreport",
          helpers.axiosConfig()
        )
        .then(response => {
          this.setState({ reloading: false });
        })
        .catch(error => {
          this.setState({ reloading: false });
          console.log(error);
        });
    }
  };

  formValid() {
    let invalidFieldsList = [];

    if (this.firstName.current.value.trim() === "") {
      invalidFieldsList.push({
        fieldName: "FirstName",
        messageCode: "Required"
      });
    }

    if (this.lastName.current.value.trim() === "") {
      invalidFieldsList.push({
        fieldName: "LastName",
        messageCode: "Required"
      });
    }

    if (this.emailAddress.current.value.trim() === "") {
      invalidFieldsList.push({
        fieldName: "EmailAddress",
        messageCode: "Required"
      });
    }

    if (this.emailAddress.current.value.trim().length < 3) {
      invalidFieldsList.push({
        fieldName: "EmailAddress",
        messageCode: "Invalid_Length"
      });
    }

    if (invalidFieldsList.length > 0) {
      this.setState({
        invalidFields: invalidFieldsList
      });

      return false;
    } else {
      var registerInfo = [];

      registerInfo.push(
        this.firstName.current.value,
        this.lastName.current.value,
        this.emailAddress.current.value
      );

      // Pass to App.js
      this.props.collectedData(registerInfo);

      this.setState({
        invalidFields: []
      });

      return true;
    }
  }

  getToken = (signInSite) => {
    axios
      .post(Config.url + "/api/v1/Auth", {
        emailAddress: this.emailAddress.current.value
      })
      .then(response => {
        this.setState({ registered: signInSite });
        helpers.setTokensWithTimes(
          response.data.access_token,
          response.data.refresh_token,
          response.data.expires_in
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="Register">
        <div className="Container HeightPadding">
          <div className="StructureTable">
            <div className="RightCell">
              <div className="SignInForm" style={{ display: this.state.displayLoginForm ? 'block' : 'none' }}>
                <h1>
                  <Translate id="Register" />
                </h1>

                <div className="Form">
                  <p className="DarkText">
                    <Translate id="Register_01" />
                  </p>

                  <div className="LineSpacer4" />

                  <div
                    className={
                      "FormBox W47 " +
                      (helpers.fieldValid(
                        "FirstName",
                        this.state.invalidFields
                      ) === true
                        ? ""
                        : "Highlighted")
                    }
                  >
                    <div className="FormLabel">
                      <label htmlFor="FirstName">
                        <Translate id="FirstName" />
                      </label>
                    </div>
                    <div className="FormField">
                      <input
                        ref={this.firstName}
                        type="text"
                        maxLength="20"
                        name="name"
                        id="FirstName"
                        className="Input"
                      />
                      <span className="ResponseMessage">
                        <Translate
                          id={helpers.fieldErrorCode(
                            "FirstName",
                            this.state.invalidFields
                          )}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="BoxSpacerW6" />

                  <div
                    className={
                      "FormBox W47 " +
                      (helpers.fieldValid(
                        "LastName",
                        this.state.invalidFields
                      ) === true
                        ? ""
                        : "Highlighted")
                    }
                  >
                    <div className="FormLabel">
                      <label htmlFor="LastName">
                        <Translate id="LastName" />
                      </label>
                    </div>
                    <div className="FormField">
                      <input
                        ref={this.lastName}
                        type="text"
                        maxLength="20"
                        name="lastname"
                        id="LastName"
                        className="Input"
                      />
                      <span className="ResponseMessage">
                        <Translate
                          id={helpers.fieldErrorCode(
                            "LastName",
                            this.state.invalidFields
                          )}
                        />
                      </span>
                    </div>
                  </div>

                  <div
                    className={
                      "FormBox " +
                      (helpers.fieldValid(
                        "EmailAddress",
                        this.state.invalidFields
                      ) === true
                        ? ""
                        : "Highlighted")
                    }
                  >
                    <div className="FormLabel">
                      <label htmlFor="Email">
                        <Translate id="EmailAddress" />
                      </label>
                    </div>
                    <div className="FormField">
                      <input
                        ref={this.emailAddress}
                        type="email"
                        maxLength="50"
                        name="email"
                        id="emailAddress"
                        className="Input"
                      />
                      <span className="ResponseMessage">
                        <Translate
                          id={helpers.fieldErrorCode(
                            "EmailAddress",
                            this.state.invalidFields
                          )}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="BottomFix" />
                </div>
              </div>

              <div style={{ display: this.state.alreadyRegistered ? 'block' : 'none' }}>
                <p className="DarkText"><Translate id="ReportRedownload" /></p>
                <div className="BtnSideBySide">
                  <div className="BtnBlock">
                    <div className="Btn">
                      <span onClick={() => { this.setState({ displayLoginForm: true, alreadyRegistered: false }); }}>
                        <Translate id="Back" />
                      </span>
                    </div>
                  </div>
                  <div className="BtnBlock">
                    <div className="Btn">
                      <span onClick={() => this.redownloadReport()}>
                        <Translate id="Resend_Report" />
                        <div className={this.state.reloading ? 'VisibleLoader' : 'HiddenLoader'}>
                          <div className="LoaderContainer">
                            <Translate id="GeneratingYourGuide" />
                            <div className="Loader" />
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="BottomFix"></div>
                </div>
              </div>
            </div>

            <div className="LeftCell">
              <div className="Relative TopSpaceRegisterPage" style={{ display: this.state.displayLoginForm ? 'block' : 'none' }}>
                <div
                  className={
                    "Btn" + (this.state.loading ?
                      "" : "")
                  }
                >
                  <span onClick={() => this.handleForm()}>
                    <Translate id="Register" />
                  <div className={this.state.loading ? 'VisibleLoader' : 'HiddenLoader'}>
                      <div className="LoaderContainer">
                        <Translate id="ValidateEmail" />
                        <div className="Loader" />
                      </div>
                    </div>
                  </span>
                </div>

              </div>
            </div>

          </div>
        </div>
        {this.state.registered ? <Redirect to="/strengths" /> : null}
      </div>
    );
  }
}
export default withLocalize(Register);
