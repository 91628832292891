import React from "react";
import { withLocalize, Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

export class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessibilityVal: false
    };

    if (sessionStorage.getItem("Accessibility") === "true") {
      this.state.accessibilityVal = true;
      this.props.accessibilityToggle(true);
    }
  }

  sendAccessToggleValue = () => {
    this.setState(prevState => ({
      accessibilityVal: !this.state.accessibilityVal
    }), function () {
      this.props.accessibilityToggle(this.state.accessibilityVal);
    });
  }

  render() {
    return (
      <footer className="SiteFooter">
        <div className="Container">
          <div className="Row">
            <div className="Cell SelfMiddle">
              <div className="FooterLinks">
                <ul>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  <li>|</li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>|</li>
                  <li>
                    <span onClick={this.sendAccessToggleValue}>
                      {this.state.accessibilityVal ? (
                        <Translate id="Accessibility_On" />
                      ) : (
                        <Translate id="Accessibility_Off" />
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Cell SelfMiddle">
              <div className="Credits">
                &copy; Strengthscope  All Rights Reserved.
              </div>
            </div>
            <div className="Cell SelfMiddle">
              <div className="ImgFooter">
                <img src={require("../../images/Logo.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withLocalize(Footer);
