import React from "react";
import CourageSmall from "../images/Img_Card_Big_Emotional_01.png";
import EmControlSmall from "../images/Img_Card_Big_Emotional_02.png";
import EnthusiasmSmall from "../images/Img_Card_Big_Emotional_03.png";
import OptimismSmall from "../images/Img_Card_Big_Emotional_04.png";
import ResilienceSmall from "../images/Img_Card_Big_Emotional_05.png";
import SCSmall from "../images/Img_Card_Big_Emotional_06.png";

import CollaborationSmall from "../images/Img_Card_Big_Relational_01.png";
import CompassionSmall from "../images/Img_Card_Big_Relational_02.png";
import DOSmall from "../images/Img_Card_Big_Relational_03.png";
import EmpathySmall from "../images/Img_Card_Big_Relational_04.png";
import LeadingSmall from "../images/Img_Card_Big_Relational_05.png";
import PersuasivenessSmall from "../images/Img_Card_Big_Relational_06.png";
import RBSmall from "../images/Img_Card_Big_Relational_07.png";

import CSSmall from "../images/Img_Card_Big_Thinking_01.png";
import CreativitySmall from "../images/Img_Card_Big_Thinking_02.png";
import CTSmall from "../images/Img_Card_Big_Thinking_03.png";
import DetailOSmall from "../images/Img_Card_Big_Thinking_04.png";
import SMSmall from "../images/Img_Card_Big_Thinking_05.png";

import DecisivenessSmall from "../images/Img_Card_Big_Execution_01.png";
import EfficiencySmall from "../images/Img_Card_Big_Execution_02.png";
import FlexibilitySmall from "../images/Img_Card_Big_Execution_03.png";
import InitiativeSmall from "../images/Img_Card_Big_Execution_04.png";
import RFSmall from "../images/Img_Card_Big_Execution_05.png";
import SISmall from "../images/Img_Card_Big_Execution_06.png";

import Courage from "../images/Img_Card_Small_Emotional_01.jpg";
import EmControl from "../images/Img_Card_Small_Emotional_02.jpg";
import Enthusiasm from "../images/Img_Card_Small_Emotional_03.jpg";
import Optimism from "../images/Img_Card_Small_Emotional_04.jpg";
import Resilience from "../images/Img_Card_Small_Emotional_05.jpg";
import SC from "../images/Img_Card_Small_Emotional_06.jpg";

import Collaboration from "../images/Img_Card_Small_Relational_01.jpg";
import Compassion from "../images/Img_Card_Small_Relational_02.jpg";
import DO from "../images/Img_Card_Small_Relational_03.jpg";
import Empathy from "../images/Img_Card_Small_Relational_04.jpg";
import Leading from "../images/Img_Card_Small_Relational_05.jpg";
import Persuasiveness from "../images/Img_Card_Small_Relational_06.jpg";
import RB from "../images/Img_Card_Small_Relational_07.jpg";

import CS from "../images/Img_Card_Small_Thinking_01.jpg";
import Creativity from "../images/Img_Card_Small_Thinking_02.jpg";
import CT from "../images/Img_Card_Small_Thinking_03.jpg";
import DetailO from "../images/Img_Card_Small_Thinking_04.jpg";
import SM from "../images/Img_Card_Small_Thinking_05.jpg";

import Decisiveness from "../images/Img_Card_Small_Execution_01.jpg";
import Efficiency from "../images/Img_Card_Small_Execution_02.jpg";
import Flexibility from "../images/Img_Card_Small_Execution_03.jpg";
import Initiative from "../images/Img_Card_Small_Execution_04.jpg";
import RF from "../images/Img_Card_Small_Execution_05.jpg";
import SI from "../images/Img_Card_Small_Execution_06.jpg";

import CourageMini from "../images/Img_Card_Mini_Emotional_01.png";
import EmControlMini from "../images/Img_Card_Mini_Emotional_02.png";
import EnthusiasmMini from "../images/Img_Card_Mini_Emotional_03.png";
import OptimismMini from "../images/Img_Card_Mini_Emotional_04.png";
import ResilienceMini from "../images/Img_Card_Mini_Emotional_05.png";
import SCMini from "../images/Img_Card_Mini_Emotional_06.png";

import CollaborationMini from "../images/Img_Card_Mini_Relational_01.png";
import CompassionMini from "../images/Img_Card_Mini_Relational_02.png";
import DOMini from "../images/Img_Card_Mini_Relational_03.png";
import EmpathyMini from "../images/Img_Card_Mini_Relational_04.png";
import LeadingMini from "../images/Img_Card_Mini_Relational_05.png";
import PersuasivenessMini from "../images/Img_Card_Mini_Relational_06.png";
import RBMini from "../images/Img_Card_Mini_Relational_07.png";

import CSMini from "../images/Img_Card_Mini_Thinking_01.png";
import CreativityMini from "../images/Img_Card_Mini_Thinking_02.png";
import CTMini from "../images/Img_Card_Mini_Thinking_03.png";
import DetailOMini from "../images/Img_Card_Mini_Thinking_04.png";
import SMMini from "../images/Img_Card_Mini_Thinking_05.png";

import DecisivenessMini from "../images/Img_Card_Mini_Execution_01.png";
import EfficiencyMini from "../images/Img_Card_Mini_Execution_02.png";
import FlexibilityMini from "../images/Img_Card_Mini_Execution_03.png";
import InitiativeMini from "../images/Img_Card_Mini_Execution_04.png";
import RFMini from "../images/Img_Card_Mini_Execution_05.png";
import SIMini from "../images/Img_Card_Mini_Execution_06.png";

export const StrengthsIcons = params => {
  let imageUrl = "";
  if (params.imageSize === "xl") {
    if (params.code === "Courage") {
      imageUrl = Courage;
    }
    if (params.code === "EmControl") {
      imageUrl = EmControl;
    }
    if (params.code === "Enthusiasm") {
      imageUrl = Enthusiasm;
    }
    if (params.code === "Optimism") {
      imageUrl = Optimism;
    }
    if (params.code === "Resilience") {
      imageUrl = Resilience;
    }
    if (params.code === "SC") {
      imageUrl = SC;
    }

    if (params.code === "Collaboration") {
      imageUrl = Collaboration;
    }
    if (params.code === "Compassion") {
      imageUrl = Compassion;
    }
    if (params.code === "DO") {
      imageUrl = DO;
    }
    if (params.code === "Empathy") {
      imageUrl = Empathy;
    }
    if (params.code === "Leading") {
      imageUrl = Leading;
    }
    if (params.code === "Persuasiveness") {
      imageUrl = Persuasiveness;
    }
    if (params.code === "RB") {
      imageUrl = RB;
    }

    if (params.code === "CS") {
      imageUrl = CS;
    }
    if (params.code === "Creativity") {
      imageUrl = Creativity;
    }
    if (params.code === "CT") {
      imageUrl = CT;
    }
    if (params.code === "DetailO") {
      imageUrl = DetailO;
    }
    if (params.code === "SM") {
      imageUrl = SM;
    }

    if (params.code === "Decisiveness") {
      imageUrl = Decisiveness;
    }
    if (params.code === "Efficiency") {
      imageUrl = Efficiency;
    }
    if (params.code === "Flexibility") {
      imageUrl = Flexibility;
    }
    if (params.code === "Initiative") {
      imageUrl = Initiative;
    }
    if (params.code === "RF") {
      imageUrl = RF;
    }
    if (params.code === "SI") {
      imageUrl = SI;
    }
  }

  if (params.imageSize === "big") {
    if (params.code === "Courage") {
      imageUrl = CourageSmall;
    }
    if (params.code === "EmControl") {
      imageUrl = EmControlSmall;
    }
    if (params.code === "Enthusiasm") {
      imageUrl = EnthusiasmSmall;
    }
    if (params.code === "Optimism") {
      imageUrl = OptimismSmall;
    }
    if (params.code === "Resilience") {
      imageUrl = ResilienceSmall;
    }
    if (params.code === "SC") {
      imageUrl = SCSmall;
    }

    if (params.code === "Collaboration") {
      imageUrl = CollaborationSmall;
    }
    if (params.code === "Compassion") {
      imageUrl = CompassionSmall;
    }
    if (params.code === "DO") {
      imageUrl = DOSmall;
    }
    if (params.code === "Empathy") {
      imageUrl = EmpathySmall;
    }
    if (params.code === "Leading") {
      imageUrl = LeadingSmall;
    }
    if (params.code === "Persuasiveness") {
      imageUrl = PersuasivenessSmall;
    }
    if (params.code === "RB") {
      imageUrl = RBSmall;
    }

    if (params.code === "CS") {
      imageUrl = CSSmall;
    }
    if (params.code === "Creativity") {
      imageUrl = CreativitySmall;
    }
    if (params.code === "CT") {
      imageUrl = CTSmall;
    }
    if (params.code === "DetailO") {
      imageUrl = DetailOSmall;
    }
    if (params.code === "SM") {
      imageUrl = SMSmall;
    }

    if (params.code === "Decisiveness") {
      imageUrl = DecisivenessSmall;
    }
    if (params.code === "Efficiency") {
      imageUrl = EfficiencySmall;
    }
    if (params.code === "Flexibility") {
      imageUrl = FlexibilitySmall;
    }
    if (params.code === "Initiative") {
      imageUrl = InitiativeSmall;
    }
    if (params.code === "RF") {
      imageUrl = RFSmall;
    }
    if (params.code === "SI") {
      imageUrl = SISmall;
    }
  }

  if (params.imageSize === "small") {
    if (params.code === "Courage") {
      imageUrl = CourageMini;
    }
    if (params.code === "EmControl") {
      imageUrl = EmControlMini;
    }
    if (params.code === "Enthusiasm") {
      imageUrl = EnthusiasmMini;
    }
    if (params.code === "Optimism") {
      imageUrl = OptimismMini;
    }
    if (params.code === "Resilience") {
      imageUrl = ResilienceMini;
    }
    if (params.code === "SC") {
      imageUrl = SCMini;
    }

    if (params.code === "Collaboration") {
      imageUrl = CollaborationMini;
    }
    if (params.code === "Compassion") {
      imageUrl = CompassionMini;
    }
    if (params.code === "DO") {
      imageUrl = DOMini;
    }
    if (params.code === "Empathy") {
      imageUrl = EmpathyMini;
    }
    if (params.code === "Leading") {
      imageUrl = LeadingMini;
    }
    if (params.code === "Persuasiveness") {
      imageUrl = PersuasivenessMini;
    }
    if (params.code === "RB") {
      imageUrl = RBMini;
    }

    if (params.code === "CS") {
      imageUrl = CSMini;
    }
    if (params.code === "Creativity") {
      imageUrl = CreativityMini;
    }
    if (params.code === "CT") {
      imageUrl = CTMini;
    }
    if (params.code === "DetailO") {
      imageUrl = DetailOMini;
    }
    if (params.code === "SM") {
      imageUrl = SMMini;
    }

    if (params.code === "Decisiveness") {
      imageUrl = DecisivenessMini;
    }
    if (params.code === "Efficiency") {
      imageUrl = EfficiencyMini;
    }
    if (params.code === "Flexibility") {
      imageUrl = FlexibilityMini;
    }
    if (params.code === "Initiative") {
      imageUrl = InitiativeMini;
    }
    if (params.code === "RF") {
      imageUrl = RFMini;
    }
    if (params.code === "SI") {
      imageUrl = SIMini;
    }
  }
  let result = "";

  if (params.type === "image")
    result = <img src={imageUrl} alt={params.code} />;
  else
    result = (
      <div
        className="BackgroundImage"
        style={{ backgroundImage: "url(" + imageUrl + ")" }}
      >
        {params.children}
      </div>
    );

  return result;
}
export default StrengthsIcons;
